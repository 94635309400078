import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Button from '../components/button.jsx';
import Layout from '../components/layout.jsx';
import Section from '../components/section.jsx';
import TitleBlock from '../components/title-block.jsx';
import classNames from 'classnames';
import Hero from '../components/hero.jsx';

const TAGS = [
	'Artificial intelligence',
	'Internship',
	'Computer vision',
	'Neural networks',
	'Intelligent document processing',
	'Klassif.ai',
	'NLP',
	'Machine Learning',
];

export default function Blogs(props) {
	let { data } = props;
	let { sanityBlogsPage, allBlogs } = data;
	let { title, seo } = sanityBlogsPage;
	let { nodes: blogs } = allBlogs;
	let [activeTags, setActiveTags] = useState([]);

	let renderBlogs = [];
	for (let blog of blogs) {
		let hasMatchingActiveTag = activeTags.length === 0;
		for (let tag of activeTags) {
			if (blog.topics.some((c) => c.name.toUpperCase() === tag.toUpperCase())) {
				hasMatchingActiveTag = true;
				break;
			}
		}

		if (!hasMatchingActiveTag) continue;

		let date = new Date(blog.published);
		let splits = blog.url.split('/');
		let slug = splits[splits.length - 1];

		renderBlogs.push(
			<li key={blog.id} className="grid gap-8 px-6 py-16 lg:gap-12 xl:grid-cols-2 items-x-center">
				<div className="grid gap-4 grid-rows-[max-content,1fr,max-content]">
					<Link to={slug} className="text-3xl text-primary focus:outline-none focus:underline">
						{blog.title}
					</Link>
					<p dangerouslySetInnerHTML={{ __html: blog.summary }} />
					<div className="flex flex-col-reverse items-start sm:justify-between sm:items-center sm:flex-row">
						<span className="mt-4 lg:mt-0">
							<Button as="link" ariaLabel={blog.title} to={slug}>
								Read more
							</Button>
						</span>
						<span className="space-x-6 text-tertiary">
							<span>{blog.author.name === '' ? 'Brainjar' : blog.author.name}</span>
							<span>{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</span>
						</span>
					</div>
				</div>

				<img
					src={blog.feature_image?.url}
					className="object-cover w-full row-start-1 self-y-center xl:row-start-auto lg:w-4/5 h-72 rounded-2xl"
				/>
			</li>,
		);
	}

	let renderTags = [];
	for (let tag of TAGS) {
		let active = activeTags.some((at) => at.toUpperCase() === tag.toUpperCase());
		function onClick() {
			if (active) {
				setActiveTags([...activeTags.filter((at) => at.toUpperCase() !== tag.toUpperCase())]);
			} else {
				setActiveTags([...activeTags, tag]);
			}
		}

		let className = classNames(
			'px-2 py-1 mx-2 my-2 border rounded-full border-primary text-primary focus:outline-none focus-visible:bg-primary focus-visible:text-secondary',
			{ 'text-secondary bg-primary': active, 'text-primary': !active },
		);

		renderTags.push(
			<li key={tag}>
				<button onClick={onClick} className={className}>
					{tag}
				</button>
			</li>,
		);
	}

	return (
		<Layout seo={seo}>
			<Hero>
				<div className="grid gap-12 lg:grid-cols-2">
					<h1 className="text-4xl md:text-5xl 2xl:text-6xl self-y-center">
						<TitleBlock title={title} />
					</h1>

					<div className="relative w-full rounded-full lg:h-full lg:w-auto lg:max-w-none">
						<StaticImage src="../images/hero-blog.svg" alt="Building" />
					</div>
				</div>
			</Hero>

			<Section>
				<ul className="flex flex-wrap items-center justify-center">{renderTags}</ul>
				<ul className="flex flex-col divide-y-2 divide-primary">{renderBlogs}</ul>
			</Section>

			<Section>
				<div className="flex justify-center max-w-3xl mx-auto">
					<StaticImage src="../images/ship-dock.svg" alt="Ship with dock" />
				</div>
			</Section>
		</Layout>
	);
}

export const query = graphql`
	query BlogsPage($language: String!) {
		sanityBlogsPage(lang: { eq: $language }) {
			title {
				children {
					_key
					text
					marks
				}
			}
			seo {
				description
				image {
					asset {
						url
					}
				}
			}
		}
		allBlogs: allHubspotPost(
			filter: { state: { eq: "PUBLISHED" }, topics: { elemMatch: { slug: { eq: "brainjar" } } } }
		) {
			nodes {
				id
				title
				url
				published
				summary
				body
				author {
					name
				}
				topics {
					name
				}
				feature_image {
					url
				}
			}
		}
	}
`;
